import * as React from "react"
import Layout from "../../components/layout"
import Star from "../../components/star"
import '../../style.css'

const SatelliteModel = () => {

    const info={
        title:'The Swift Satellite Model',
        image:'sat3.png',
        page:'satelliteModel',
        description:'This booklet contains all the instructions, drawings, and descriptions needed to build a paper model of the Swift satellite. It was inspired by the model originated by the Italian Swift E/PI group, and adheres to the national technology standards. Almost all of the work to produce the English version of the model was done by Aurore Simonnet',
        internalText:["This booklet contains all the instructions, drawings, and descriptions needed to build a paper model of the Swift satellite. It was inspired by the model originated by the Italian Swift E/PI group, and adheres to the national technology standards. Almost all of the work to produce the English version of the model was done by Aurore Simonnet"],
        pdf:{
          pdf:'modelGuide.pdf',
          accessible:'modelGuideDisability.pdf'
        }
    }

  return (
    <Layout>
        <div>
          <h1>{info.title}</h1>
          <Star/>
          <p>{info.internalText}</p>
          {info.pdf.accessible?
          <p>Check out the <a href={`/resourceLinks/${info.pdf.pdf}`} target='_blank' rel="noreferrer">PDF</a> and <a href={`/resourceLinks/${info.pdf.accessible}`} target='_blank' rel="noreferrer">Disability accessible PDF</a></p>
          :
          <p>Check out the <a href={`/resourceLinks/${info.pdf.pdf}`} target='_blank' rel="noreferrer">PDF</a></p>
          }        
        </div>
    </Layout>
  )
}

export default SatelliteModel